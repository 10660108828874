import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Ipad from "../components/Ipad.js"
import { Link } from "gatsby"
import triangleAz from "../images/triangle-az.png"

const Index = () => {
  return (
    <Ipad data={{ bgColor: "white" }}>
      <Background>
        <div className="fullPic"></div>
        <div className="blue" />
      </Background>
      <Container>
        <StaticImage className="logo" src="../images/logo-az.png" alt="logo" />
        <div className="chunk">
          <div className="claim">
            <img src={triangleAz} alt="triangle" className="triangle" />
            <h1>REDISCOVER EARTH.</h1>
            <img src={triangleAz} alt="triangle" className="triangle" />
          </div>
          <div className="azul">
            <p>UNA NUEVA EXPERIENCIA DE VIAJE PARA UNA NUEVA TIERRA.</p>
            <p style={{ marginTop: "10px" }}>desde 2030</p>
          </div>
        </div>
        <Link to="/zero">
          <button>Encuentra tu futuro viaje</button>
        </Link>
      </Container>
    </Ipad>
  )
}

const Container = styled.section`
  height: 90%;
  padding: 5% 0;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: GEB;
  .logo {
    width: 130px;
  }
  .chunk {
    text-align: center;
    .azul {
      color: var(--azul);
    }
    .claim {
      display: flex;
      margin: 0 auto;
      align-items: center;
      margin-bottom: 10px;
      h1 {
        font-size: 3.2rem;
        margin: 0 50px;
      }
      .triangle {
        align-self: center;
        width: 40px;
      }
    }
    h1,
    p {
      margin: 0;
    }
    p {
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
  }
  button {
    border: 1px solid var(--azul);
    background: transparent;
    color: white;
    font-family: GEB;
    padding: 1.4rem 2.4rem;
    border-radius: 4rem;
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
  }
`

const Background = styled.section`
  div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .blue {
    mix-blend-mode: multiply;
    background-color: rgba(18, 2, 184);
  }
`

export default Index
